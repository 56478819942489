import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import classnames from 'classnames';

import {
  CategoryAllProducts,
  CategoryBuilders,
  CategorySubcontractors,
  CategorySiteWorkers,
  CategoryCompliance,
  ProductCategories,
  TradeUp,
  WorkUp,
  ServiceUp,
  UpFamily,
  CheckUp,
  ProjectUp,
  EnquireUrl,
  SignupUrl
}  from '@constants/app';
import { Routes } from '@constants/routes';

export const CategoryNav = ({
  activeCategory
}) => {
  return (
    <ul className="nav justify-content-center">
      <li className="nav-item">
        <Link 
          className={classnames("nav-link", activeCategory === CategoryAllProducts ? 'active' : '')}
          to={Routes.Discover}
        >{CategoryAllProducts}</Link>
      </li>
      <li className="nav-item">
        <Link 
          className={classnames("nav-link", activeCategory === CategoryBuilders ? 'active' : '')}
          to={Routes.DiscoverBuilders}
        >{CategoryBuilders}</Link>
      </li>
      <li className="nav-item">
        <Link 
          className={classnames("nav-link", activeCategory === CategorySubcontractors ? 'active' : '')}
          to={Routes.DiscoverSubcontractors}
        >{CategorySubcontractors}</Link>
      </li>
      <li className="nav-item">
        <Link 
          className={classnames("nav-link", activeCategory === CategorySiteWorkers ? 'active' : '')}
          to={Routes.DiscoverSiteWorkers}
        >{CategorySiteWorkers}</Link>
      </li>
      <li className="nav-item">
        <Link
          className={classnames("nav-link", activeCategory === CategoryCompliance ? 'active' : '')}
          to={Routes.DiscoverCompliance}
        >{CategoryCompliance}</Link>
      </li>
    </ul>
  );
}

export default ({
  productCategory,
  title,
  description,
  tradeUpDescription,
  workUpDescription,
  serviceUpDescription,
  projectUpDescription,
  checkUpDescription,
  upFamilyDescription
}) => {

  const products = ProductCategories[productCategory];

	const data = useStaticQuery(graphql`
		query {
			logoProjectUp: allContentfulAsset(filter: {title: {eq: "logoProjectUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
			logoCheckUp: allContentfulAsset(filter: {title: {eq: "logoCheckUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
			logoTradeUp: allContentfulAsset(filter: {title: {eq: "logoTradeUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
			logoServiceUp: allContentfulAsset(filter: {title: {eq: "logoServiceUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
			logoWorkUp: allContentfulAsset(filter: {title: {eq: "logoWorkUp"}}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
			}
      logoUpFamily: allContentfulAsset(filter: {title: { eq: "logoUpFamily" }}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
      }
		}
	`);

	const logoProjectUpUrl = data.logoProjectUp.edges[0].node.file.url;
	const logoTradeUpUrl = data.logoTradeUp.edges[0].node.file.url;
	const logoServiceUpUrl = data.logoServiceUp.edges[0].node.file.url;
	const logoWorkUpUrl = data.logoWorkUp.edges[0].node.file.url;
	const logoCheckUpUrl = data.logoCheckUp.edges[0].node.file.url;
  const logoUpFamilyUrl = data.logoUpFamily.edges[0].node.file.url;

  return (
    <section className="mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="flex-center flex-column text-center py-5">
              <h2>{title}</h2>
              <CategoryNav
                activeCategory={productCategory}
              />
              <p className="mb-0 mt-5">{description}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {products.map((product, i) => (
            <div key={i} className="col-md-4 d-flex">
              <div className="card mt-4 flex-grow">
                <div className="flex-center card-img-top">
                  { product === TradeUp ?
                    <img
                      src={logoTradeUpUrl}
                      alt="logo"
                      className="mt-4"
                    /> : null
                  }
                  { product === WorkUp ?
                    <img
                      src={logoWorkUpUrl}
                      alt="logo"
                      className="mt-4"
                    /> : null
                  }
                  { product === ServiceUp ?
                    <img
                      src={logoServiceUpUrl}
                      alt="logo"
                      className="mt-4"
                    /> : null
                  }
                  { product === ProjectUp ?
                    <img
                      src={logoProjectUpUrl}
                      alt="logo"
                      className="mt-4"
                    /> : null
                  }
                  { product === CheckUp ?
                    <img
                      src={logoCheckUpUrl}
                      alt="logo"
                      className="mt-4"
                    /> : null
                  }
                  { product === UpFamily ?
                    <img
                      src={logoUpFamilyUrl}
                      alt="logo"
                      className="mt-4"
                    /> : null
                  }
                </div>
                <div className="card-body">
                  { product === TradeUp ?
                    <p>{tradeUpDescription}</p> : null
                  }
                  { product === WorkUp ?
                    <p>{workUpDescription}</p> : null
                  }
                  { product === ServiceUp ?
                    <p>{serviceUpDescription}</p> : null
                  }
                  { product === ProjectUp ?
                    <p>{projectUpDescription}</p> : null
                  }
                  { product === CheckUp ?
                    <p>{checkUpDescription}</p> : null
                  }
                  { product === UpFamily ?
                    <p>{upFamilyDescription}</p> : null
                  }
                </div>
                <div className="card-footer bg-white border-0">

                  { product === TradeUp ?
                    <div className="d-flex justify-content-between align-items-center">
                      <a href={Routes.TradeUp}>Learn more &#8250;</a>
                      <a href={EnquireUrl} className="btn btn-primary">Enquire</a>
                    </div> : null
                  }
                  { product === WorkUp ?
                    <div className="d-flex justify-content-between align-items-center">
                      <a href={Routes.WorkUp}>Learn more &#8250;</a>
                      <a href={SignupUrl} className="btn btn-primary">Sign Up Now</a>
                    </div> : null
                  }
                  { product === ServiceUp ?
                    <div className="d-flex justify-content-between align-items-center">
                      <a href={Routes.ServiceUp}>Learn more &#8250;</a>
                      <a href={EnquireUrl} className="btn btn-primary">Enquire</a>
                    </div> : null
                  }
                  { product === ProjectUp ?
                    <div className="d-flex justify-content-between align-items-center">
                      <a href={Routes.ProjectUp}>Learn more &#8250;</a>
                      <a href={Routes.ContactUs} className="btn btn-primary">Register Interest</a>
                    </div> : null
                  }
                  { product === CheckUp ?
                    <div className="d-flex justify-content-between align-items-center">
                      <a href={Routes.CheckUp}>Learn more &#8250;</a>
                      <a href={Routes.ContactUs} className="btn btn-primary">Register Interest</a>
                    </div> : null
                  }
                  { product === UpFamily ?
                    <div className="d-flex justify-content-between align-items-center">
                      <a href={Routes.UpFamily}>Learn more &#8250;</a>
                      <a href={Routes.ContactUs} className="btn btn-primary">Enquire Now</a>
                    </div> : null
                  }

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

