import React from 'react';
import Img from "gatsby-image"

import './banner.scss';

export default ({
  assets,
  imageTitle,
  children
}) => {
  const banner = assets.filter(asset => asset.title === imageTitle)[0];

  return (
    <section className="banner-section w-100 position-relative">
      <Img
        fluid={banner.fluid}
        alt={banner.title}
        className="banner"
      />
      <div className="flex-center container py-0 py-sm-5 position-relative" style={{ zIndex: 2 }}>
        <div className="w-100 row text-center text-md-left">
          <div className="col-md-6">
            { children }
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
    </section>
  );
};
